@-webkit-keyframes pulseSmall {
  0% {
    -webkit-box-shadow: 0 0 0 0 #ee3364;
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(238, 51, 100, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(238, 51, 100, 0);
  }
}
@keyframes pulseSmall {
  0% {
    box-shadow: 0 0 0 0 #3271e6;
  }
  70% {
    box-shadow: 0 0 0 10px rgba(238, 51, 100, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(238, 51, 100, 0);
  }
}
@-webkit-keyframes pulseBig {
  0% {
    -webkit-box-shadow: 0 0 0 0 #ee3364;
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(238, 51, 100, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(238, 51, 100, 0);
  }
}
@keyframes pulseBig {
  0% {
    box-shadow: 0 0 0 0 #ee3364;
  }
  70% {
    box-shadow: 0 0 0 20px rgba(238, 51, 100, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(238, 51, 100, 0);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

@keyframes sweep {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
@keyframes hvr-pop {
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}

@-webkit-keyframes pop-top {
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes pop-top {
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}
