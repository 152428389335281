// [class^='ribbon-'] {
//   position: relative;
// }
// [class^='ribbon-']:before,
// [class^='ribbon-']:after {
//   content: '';
//   position: absolute;
// }
// .ribbon-2 {
//   width: 70px;
//   height: 30px;
//   background: #21749a;
//   left: -8px;
// }
// .ribbon-2:before {
//   height: 0;
//   width: 0;
//   border-bottom: 8px solid #14455b;
//   border-left: 8px solid transparent;
//   top: -8px;
// }
// .ribbon-2:after {
//   height: 0;
//   width: 0;
//   border-top: 15px solid transparent;
//   border-bottom: 15px solid transparent;
//   border-left: 7px solid #21749a;
//   right: -7px;
// }

.ribbon {
  height: 35px;
  background: var(--primary-color);
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 1rem 15px 1.5rem;
  top: 15px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
  }
  &::before {
    border-bottom: 8px solid transparent;
    top: -8px;
  }

  &-left {
    left: -8px;
    border-radius: 0 5px 5px 20px;
    &::before {
      border-left: 8px solid transparent;
      top: -8px;
      left: 0;
    }
  }
  &-right {
    right: -8px;
    border-radius: 7px 0 20px 7px;
    &::before {
      border-right: 8px solid transparent;
      top: -8px;
      right: 0;
    }
  }

   &-primary {
    $color: var(--primary-color--rgb);

    background: rgba($color, 0.85);
    color: white;

    &::before {
      border-bottom: 8px solid rgba($color, 1);
    }
    &::after {
      border-left: 7px solid rgba($color, 0.85);
    }
  }
  &-red {
    $color: var(--main-red--rgb);

    background: rgba($color, 0.85);
    color: white;

    &::before {
      border-bottom: 8px solid rgba($color, 1);
    }
    &::after {
      border-left: 7px solid rgba($color, 0.85);
    }
  }
  &-green {
    $color: var(--main-green--rgb);
    background: rgba($color, 0.85);

    &::before {
      border-bottom: 8px solid rgba($color, 1);
    }
    &::after {
      border-left: 7px solid rgba($color, 0.85);
    }
  }
}
