.yds-pagination {
  .page-link {
    width: 40px;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 8px;
    border: none;
    border-radius: 12px !important;
    box-shadow: none;
    color: $gray-soft;
    background-color: var(--bg-light);
  }
  .page-item {
    &.active {
      .page-link {
        background-color: var(--main-red);
        color: white;
      }
    }
    &.disabled {
      .page-link {
        background-color: rgba($color: $gray-soft, $alpha: 0.1);
      }
    }
    &:first-child {
      .page-link {
        &::before {
          display: inline-block;
          vertical-align: 0.255em;
          content: '';
          border-top: 0.5em solid;
          border-right: 0.4em solid transparent;
          border-bottom: 0;
          border-left: 0.4em solid transparent;
          transform: rotate(90deg);
        }
      }
    }
    &:last-child {
      .page-link {
        &::before {
          display: inline-block;
          vertical-align: 0.255em;
          content: '';
          border-top: 0.5em solid;
          border-right: 0.4em solid transparent;
          border-bottom: 0;
          border-left: 0.4em solid transparent;
          transform: rotate(-90deg);
        }
      }
    }
  }
}
