.btn {
  border-radius: 14px;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 10px;
  color: var(--font-color) !important;

  &-outline-primary {
    border: 1px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
    &:hover {
      background-color: var(--primary-color) !important;
      color: white !important;
    }
  }

  &-primary {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
    color: white !important;
  }
  &-white {
    background-color: var(--bg-light);
    svg {
      //fill: var(--font-color);
    }
  }
  &-danger {
    color: white !important;
  }

  &-with-ico {
    display: flex;
    align-items: center;

    > .ico {
      min-width: 35px;
      max-width: 35px;
      height: 35px;
      border-radius: 8px;
      @extend .flex-centered;
      padding: 7px;
    }
    > .text {
      padding: 0 1.3rem;
    }
  }
}
