.gridjs-wrapper {
  box-shadow: none;
}
.gridjs-container {
  padding: 0;
}
th.gridjs-th {
  padding: 10px;
  font-weight: 600;
  font-size: 0.8rem;
  background-color: #dbdbdb24;
  border: 1px solid var(--bg-light);
  border-top: none;
}
td.gridjs-td {
  padding: 10px;
  border: none;
  color: var(--gray-dark);
  font-weight: 600;
  font-size: 0.8rem;
}

.gridjs-footer {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 10px;
}
.gridjs-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .gridjs-summary {
    display: none;
  }
}

.gridjs-pagination .gridjs-pages button {
  padding: 0.7rem;
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border: none;
  background-color: transparent;
  border-radius: 12px;
  color: var(--gray-soft);
  &:first-child,
  &:last-child {
    width: fit-content;
    border: none;
    background-color: var(--bg-light);
    border: 1px solid var(--bg-light);
  }

  &:first-child,
  &:last-child {
    border-radius: 12px;
  }
}

.gridjs-pagination .gridjs-pages button.gridjs-currentPage {
  background-color: var(--primary-color);
  color: white;
}

.orderDetailsBtn {
  background-color: rgba(var(--main-green--rgb), 0.08);
  color: var(--main-green);
  font-weight: 600;
  padding: 7px 15px;
  border-radius: 16px;
  //border: 1px solid var(--main-green);
}

.gridjs-tbody,
td.gridjs-td {
  background-color: var(--bg-light);
}

input.gridjs-input {
  background-color: var(--bg-light);
  color: var(--font-color);
  border: 1px solid rgba(var(--gray-soft--rgb), 0.2);
  height: 40px;
  border-radius: 30px;
  padding: 5px 1rem;
}
.gridjs-pagination .gridjs-summary {
  color: var(--font-color);
}
