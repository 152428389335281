.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.audio-player {
  audio {
    background-color: var(--bg-color);
    &::-webkit-media-controls-play-button,
    &::-webkit-media-controls-panel {
      background-color: var(--bg-color);
    }
  }
}
.overlay {
  position: fixed;
  opacity: 0;
  transition: 200ms ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  z-index: 999;
  &.active {
    opacity: 1;
    pointer-events: all;
  }
}

.ico-btn {
  &:focus,
  &:active {
    outline: none;
  }
  min-width: 40px;
  max-width: 40px;
  border-radius: 12px;
  height: 40px;
  @extend .flex-centered;
}

.user-thumb-img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  max-width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 12px;
  &.small {
    min-width: 30px;
    max-width: 30px;
    height: 30px;
    border-radius: 10px;
  }
}
.load-more-btn {
  color: var(--primary-color) !important;
  background-color: var(--bg-light);
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 500;
  padding: 0.75rem 1rem !important;
  border-radius: 25px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.custom-container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.layout-box {
  padding: 1.75rem 1rem;
}
.main-content {
  padding: 0 1em;
  border-left: 1px solid rgba(rgb(199, 199, 199), 0.4);
  // box-shadow: var(--nav-shadow);
}
.dictionary-search-group {
  display: flex;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 10px 20px rgba(48, 55, 74, 0.04),
    0px 2px 6px rgba(48, 55, 74, 0.04), 0px 0px 1px rgba(48, 55, 74, 0.04);
  input {
    flex-grow: 1;
    border-radius: 16px 0 0 16px !important;
    outline: none;
    border: none;
    padding: 1rem 1.2rem !important;
    font-size: 1.1rem;
    background-color: var(--bg-light);
    width: 100%;
    color: var(--gray-soft);
    &::placeholder {
      color: rgba(var(--gray-soft--rgb), 0.5);
    }
  }
  button {
    border-radius: 0 16px 16px 0;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.dictionary-sec-title {
  font-weight: 600;
  font-size: 1.1rem;
  color: rgba(var(--gray-soft--rgb), 0.5);
  display: flex;
  align-items: center;
  &::after {
    content: '';
    display: block;
    height: 1px;
    background-color: rgba(var(--gray-soft--rgb), 0.1);
    flex-grow: 1;
    margin-left: 20px;
  }
}
.dictionary-close-mobile {
  position: absolute;
  right: 0;
  top: -5px;
  max-width: 35px;
  min-width: 35px;
  height: 35px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  border-radius: 50%;
}
.bottom-sheet__card {
  margin-left: 10px;
  margin-right: 10px;
  width: 98%;
  max-width: 95% !important;
  background: var(--bg-color) !important;
  .bottom-sheet__bar {
    background: var(--gray-soft);
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--bg-light);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gray-soft);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .bottom-sheet__content {
    padding: 1rem;
  }
  .bottom-sheet__close {
    position: absolute;
    right: -5px;
    top: -5px;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: var(--bg-light);
    box-shadow: var(--main-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--gray-soft);
    border-radius: 50%;
  }
  .bottom-sheet__pan {
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem !important;
    //box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05) !important;
    -webkit-box-shadow: 1px 20px 15px -15px rgba(0, 0, 0, 0.02);
    box-shadow: 1px 20px 15px -15px rgba(0, 0, 0, 0.02);
  }
}

#button-content {
  background-color: red;
}
.general-box {
  background-color: var(--bg-light);
  border-radius: 12px;
  box-shadow: var(--main-shadow);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.highlight-selected {
  &::selection {
    color: black !important;
    background: #f1e840e8 !important;
  }
}
.color-mode-btn {
  min-width: 45px;
  max-width: 45px;
  height: 45px;
  position: fixed;
  background-color: rgba(var(--gray-soft--rgb), 0.8);

  right: 25px;
  bottom: 15px;
  z-index: 999;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--main-shadow);
  svg {
    fill: var(--gray-soft);
  }
  &.dark {
    background-color: rgba(231, 231, 231, 0.904);
  }
}

.cs-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(3, 3, 3, 0.52);
  z-index: 999;
  overflow: auto;
  &__body {
    background-color: var(--bg-light);
    border-radius: 12px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 1rem;
    position: relative;
  }
  &__close {
    position: absolute;
    right: -8px;
    top: -8px;
    max-width: 40px;
    min-width: 40px;
    height: 40px;
    background-color: var(--bg-light);
    box-shadow: var(--main-shadow);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--gray-soft);
    border-radius: 50%;
    cursor: pointer;
  }
}

@include max-width(1024px) {
  .less-md-w100 {
    width: 100% !important;
  }
  .main-content {
    padding: 0;
  }
  .dictionary-search-group {
    input {
      padding: 0.8rem 1rem !important;
      font-size: 0.9rem;
    }
    button {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  .dictionary-close {
    display: none;
  }
  .dictionary-wrap {
    position: relative;
  }
  .konu-tarama-selects {
    display: flex;
    width: 100%;
  }
}
@include min-width(1024px) {
  .bottom-sheet__content {
    padding: 1rem 2rem !important;
  }
  .dictionary-close-mobile {
    display: none;
  }
  .cs-modal {
    &__body {
      width: 35%;
    }
    &-lg {
      width: 70%;
    }
  }
}
@include min-width(1200px) {
  .scrollable {
    &-left,
    &-right {
      height: 90vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
@include min-width(1400px) {
  .main-content {
    padding-left: 60px;
    padding-right: 60px;
    border-top-left-radius: 3rem;
  }
}
