.question-numbers {
  display: flex;
  overflow: auto;
  white-space: nowrap;
  padding: 10px 0;
  ::-webkit-scrollbar {
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(var(--bg-light--rgb), 0.7);
  }
  ::-webkit-scrollbar-thumb {
    background: var(--bg-light);
  }
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    background-color: rgba(var(--gray-dark--rgb), 0.2);
  }
  .__view {
    padding-top: 5px;
    display: flex;
  }
  .__rail-is-horizontal {
    display: none;
  }
}
.question-number {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  border-radius: 5px;
  margin-bottom: 7px;
  background-color: var(--qn-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  transition: 0.075s all ease-in-out;
  font-size: 0.8rem;
  margin-left: 7px;
  &:hover {
    transform: translateY(-3px);
  }
  &.success {
    background-color: rgba(var(--main-green--rgb), 0.8);
  }
  &.unsuccess {
    background-color: rgba(var(--main-red--rgb), 0.8);
    color: white;
  }
  &.empty {
    background-color: rgba(var(--gray-soft--rgb), 0.8);
    color: white;
  }
  &.filled {
    background-color: rgba(var(--gray-soft--rgb), 0.2);
  }
}

.quiz-tabMenu {
  flex-wrap: nowrap;
  margin-right: auto;
  margin-top: 2rem;

  .nav-item {
    .nav-link {
      height: 35px;
      padding: 0.2rem 0.75rem;
      border-radius: 10px;
      text-transform: none;
    }
  }
}

.question-explain-grid {
  display: grid;
  grid-template-columns: 65% 35%;
  gap: 0.8rem 0.8rem;
  &:nth-child(2) {
    grid-row: span 2;
  }
}
.question-options-card {
  border-radius: 12px;
  grid-row: span 2;

  .question-options {
    display: flex;
    flex-direction: column;
    .option {
      background-color: var(--bg-light);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.75rem 0.5rem;
      border: 1px solid var(--bg-light);
      border-radius: 16px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
      transition: all cubic-bezier(1, -0.07, 1, 1) 0.2s;
      > .left {
        background-color: rgba(var(--gray-dark--rgb), 0.8);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 30px;
        min-width: 30px;
        height: 20px;
        padding: 1rem;
        border-radius: 12px;
        text-transform: uppercase;
        font-weight: 500;
      }
      > .center {
        flex: 1;
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
      }
      > .right {
      }

      &.selected {
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);

        color: white;
        > .left {
          background-color: rgba(var(--bg-light--rgb), 0.8);
          color: var(--primary-color);
        }
      }
      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      &.success {
        border: 0.12rem solid rgba(var(--main-green--rgb), $alpha: 0.7);
        background-color: rgba(var(--main-green--rgb), 0.7);
      }
      &.unsuccess {
        border: 0.12rem solid rgba(var(--main-red--rgb), $alpha: 0.9);
        background-color: rgba(var(--main-red--rgb), $alpha: 0.9);
        color: white;
      }
    }
  }
}

.question-box {
  display: none;
  &.show {
    animation: sweep 0.5s ease-in-out;
    display: block;
  }
}

.question-detail-card {
  position: relative;
  padding: 2rem 0.75rem 0.75rem 0.75rem;
  background-color: var(--bg-light);
  border-radius: 12px;
  .question-number {
    position: absolute;
    left: 10px;
    top: -10px !important;
    margin-bottom: 0;
    &:hover {
      transform: translateY(0px);
      cursor: default;
    }
  }
}
.questions-review-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  > .left {
    padding: 1.2rem;
  }
  > .right {
    padding: 1.2rem;
  }
}
.question-category {
  background-color: rgba(var(--gray-soft--rgb), $alpha: 0.1);
  padding: 0.75rem 1.2rem;
  border-radius: 8px;
  font-size: 0.8rem;
  margin-left: auto;
  margin-top: -20px;
}
.all-questions-grid {
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  gap: 5px;
  .question-number {
    min-width: 100%;
    max-width: 100%;
    margin: 0;
  }
}
.review-descriptions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  li {
    margin-right: 12px;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: flex;
      min-width: 15px;
      max-width: 15px;
      height: 15px;
      border-radius: 3px;
      margin-right: 5px;
    }
  }
  > .active {
    &::before {
      background-color: var(--primary-color);
      border: 1px solid rgb(194, 194, 194);
    }
  }
  > .filled {
    &::before {
      background-color: rgba(var(--gray-soft--rgb), 0.2);
      border: 1px solid rgb(194, 194, 194);
    }
  }
  > .empty {
    &::before {
      background-color: var(--qn-color);
      border: 1px solid rgb(194, 194, 194);
    }
  }
}

.question-number {
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 7px;
  &.marked {
    $color: var(--main-orange--rgb);
    background: rgba($color, 0.85);
    color: white;
  }
  &.primary,
  &.active {
    $color: var(--primary-color--rgb);
    background: rgba($color, 0.95);
    color: white;
  }

  &.success {
    $color: var(--main-green--rgb);

    background: rgba($color, 0.85);
    color: white;
  }
  &.unsuccess {
    $color: var(--main-red--rgb);

    background: rgba($color, 0.85);
    color: white;
  }
  &.empty {
    $color: var(--gray-soft--rgb);
    background: rgba($color, 0.85);
    color: white;
  }
}
.question-explain-card {
  background-color: rgba(var(--primary-color--rgb), 0.03);
  color: rgba(var(--primary-color--rgb), 0.9);
  padding: 2rem;
  border-radius: 12px;
}

.question-type {
  background-color: var(--bg-light);
  border-radius: 16px;
  box-shadow: var(--main-shadow);
  padding: 1rem 0;
  display: grid;
  grid-template-columns: 2fr repeat(5, 1fr);
  font-size: 0.9rem;
  text-align: center;
  overflow-x: auto;

  &-item {
    border-bottom: 1px solid #d1d1d1;
    padding: 1rem;
    border-left: 1px solid #d1d1d1;
    display: grid;
    place-items: center;

    &.first {
      border-left: 1px solid transparent;
    }
    &.last {
      border-right: 1px solid transparent;
    }

    &.success {
      background-color: var(--main-green);
      color: white;
    }
    &.unsuccess {
      background-color: var(--main-red);
      color: white;
    }
  }
}

.quiz-results-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  color: rgba(var(--gray-soft--rgb), $alpha: 0.9);
  font-size: 1em;
  .result-item {
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: 0.8rem;
    > .text {
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}

.question-subdesc {
  padding: 0.75rem 1rem;
  background-color: var(--bg-light);
  border-radius: 12px;
  text-align: center;
  font-weight: 600;
  color: var(--gray-soft);
  display: flex;
  align-items: center;
  justify-content: center;
}
.quiz-control-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  > .control-btn {
    display: flex;
    align-items: center;
    color: var(--gray-soft);
    font-weight: bold;
    &.disabled {
      opacity: 0.5;
      &:hover {
        opacity: 0.5;
      }
    }

    > .ico {
      background-color: var(--primary-color);
      min-width: 40px;
      max-width: 40px;
      height: 50px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
    &.prev {
      > .ico {
        i {
          transform: rotate(90deg);
        }
      }
    }
    &.next {
      > .ico {
        i {
          transform: rotate(-90deg);
        }
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }
  > .pause {
    background-color: rgba(var(--gray-soft--rgb), 0.5);
    min-width: 40px;
    max-width: 40px;
    height: 50px;
    border-radius: 35px;

    &:hover {
      opacity: 0.8;
    }
  }
}

@include min-width(1024px) {
  .quiz-control-buttons {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .question-options-card {
    .question-options {
      width: 50%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .questions-review-grid {
    .right {
      border-left: 1px solid rgba(var(--gray-soft--rgb), $alpha: 0.5);
    }
  }
  .quiz-results-grid {
    place-items: center;
    text-align: center;
  }
}

@include max-width(1024px) {
  .all-questions-grid {
    grid-template-columns: repeat(7, 1fr);
  }
  .questions-review-grid {
    grid-template-columns: 1fr;
    > .left {
      padding: 0.5rem 0;
    }
    > .right {
      padding: 0.5rem 0;
    }
  }

  .question-explain-grid {
    grid-template-columns: 1fr;
  }
  .quiz-tabMenu {
    flex-wrap: nowrap;

    .nav-item {
      .nav-link {
        font-weight: 500;
        font-size: 12px;
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }
  .question-type {
    font-size: 0.7rem;
  }
  .quiz-results-grid {
    grid-template-columns: repeat(2, 1fr);
    font-size: 0.8rem;

    .result-item {
      padding: 0.5rem;
      > .text {
        font-size: 0.9em;
        font-weight: bold;
      }
    }
  }
}
