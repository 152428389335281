































































































































































































































































































































































































































































































































































































































































.magaza-intro {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__ico {
    min-width: 65px;
    max-width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background-color: rgba(white, 0.1);
    border-radius: 16px;
  }
}

.pulseSmall {
  animation: pulseSmall 1.5s infinite;
}
