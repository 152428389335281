.quiz-process-tab {
  display: flex;

  .tab-item {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    font-weight: 500;
    &::before {
      content: '+ ';
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bg-light);
      margin-right: 10px;
      width: 35px;
      height: 35px;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
    &.active {
      &::before {
        content: '-';
        background-color: rgba(var(--main-red--rgb), 0.8);
        color: white;
      }
    }
  }
}
.quiz-process-tab-content {
  position: relative;
  > .tab-content {
    display: none;
    &.active {
      display: block;
      animation: sweep 0.5s ease-in-out;
    }
  }
}


