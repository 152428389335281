.address-card {
  background-color: var(--bg-light);
  background-color: transparent;
  padding: 1rem;
  border-radius: 7px;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 0;
  min-height: 165px;
  height: 100%;
  border: 1px solid rgba(var(--gray-soft--rgb), 0.1);

  &.active {
    border: 1px solid var(--primary-color);
    box-shadow: rgba(var(--primary-color--rgb), 0.1) 0px 0px 16px;
  }
  .checkmark {
    position: absolute;
    right: 10px;
    top: 15px;
    border-radius: 7px;
    z-index: -1;
    background-color: var(--primary-color);
    border-radius: 25px;
  }
}
.address-title {
  min-height: 3rem;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.address-cta-btns {
  button {
    color: inherit;
  }
  svg {
    stroke: var(--font-color);
  }
}
