.vc-container {
  width: 100% !important;
}
.vc-day-popover-header {
  margin-bottom: 10px;
}
.vc-day-popover-row {
  align-items: flex-start !important;
  &-indicator {
    margin-top: 5px;
  }
}
.vc-day-popover-container {
  padding: 10px !important;
}
.vc-highlight {
  background-color: var(--primary-color);
  animation: pulseSmall 3s infinite;
}
.vc-day {
  padding: 10px;
  color: var(--font-color) !important;
}
.vc-popover-content-wrapper {
  width: 220px;
}
.vc-popover-caret {
  display: none !important;
}
