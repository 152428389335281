.layout-icon {
  width: 18px;
  height: 18px;
  fill: #c4c4c4;
  &.active {
    fill: var(--primary-color);
  }
}

.filter-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__left {
    flex-basis: auto;
  }
  &__center {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }
  &__right {
    flex-basis: auto;
    white-space: nowrap;
  }
}
.lesson-progress-grid {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;

  &.horizontal {
    grid-template-columns: 1fr;
  }
  &.vertical {
    grid-template-columns: repeat(4, 1fr);
    .grid-exam-card {
      &__left {
        > .cover {
          height: 190px;
        }
      }
      grid-template-columns: 1fr;
    }
  }
}
.lesson-sections {
  details {
    summary {
      background-color: rgba(var(--gray-soft--rgb), 0.15);
      justify-content: space-between;
      margin-bottom: 0;
      padding: 0.65rem;
      font-size: 1rem;
      border-bottom: 1px solid transparent;
      //color: $gray-soft;
      //margin-left: 10px;
      align-items: flex-start;
      i {
        transition: 0.3s;
      }
      &::before {
        content: none;
      }
      // &::after {
      //   content: '';
      //   position: relative;
      //   background-repeat: no-repeat;
      //   background-image: url('assets/icons/chevron-down.svg');
      //   background-size: 30px 30px;
      //   min-width: 30px;
      //   max-width: 30px;
      //   height: 30px;
      //   transition: 0.3s;
      //   margin-left: 10px;
      // }
    }

    .details-content {
      position: relative;
      height: 100%;
      min-height: 10vh;
      max-height: 45vh;
      padding: 0.5rem;
      overflow-y: auto;
      background-color: rgba(var(--gray-soft--rgb), 0.06);
    }
    &[open] > summary {
      border-bottom: 1px solid rgba(var(--gray-soft--rgb), 0.1);
      i {
        transform: rotate(-180deg);
      }
    }
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

@include max-width(768px) {
  .lesson-progress-grid {
    grid-template-columns: 1fr !important;
    &.vertical {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }

  .filter-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &__left {
      flex-basis: auto;
      order: 1;
    }
    &__center {
      display: flex;
      flex-basis: 100%;
      order: 3;
      flex-basis: auto;
      white-space: nowrap;
      overflow: auto;
      .tab-menu--primary {
        overflow: auto;
        //flex-wrap: nowrap;
        scroll-snap-type: x mandatory;
        margin-top: 0.5rem;
        padding: 0.5rem 0;
        .nav-item {
          scroll-snap-align: start;
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
    &__right {
      order: 2;
      flex-basis: auto;
    }
  }
}
