































































.video-card {
  &__img {
    display: flex;
    position: relative;
    border-radius: 12px;
    //background-color: #eee;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .video-badge {
      position: absolute;
      left: 10px;
      top: 10px;
      background-color: rgba(var(--primary-color--rgb), 0.8);
      backdrop-filter: blur(3px);
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 10px;
      z-index: 10;
    }
    img {
      width: 100%;
      object-fit: cover;
      object-position: top;
      border-radius: 8px;
      height: 13rem;
    }
  }
  &__body {
    color: var(--font-color);
    .title {
      font-size: 1.1rem;
    }
  }

  &__play {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #6b6b6b00;
    backdrop-filter: brightness(0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transition: 0.1s ease-in-out all;
    }
  }

  &:hover {
    .video-card__play {
      svg {
        transform: scale(1.1);
      }
    }
  }
}
