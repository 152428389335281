.word-group-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}
.word-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--bg-light);
  border-radius: 1rem;
  padding: 0.5rem;
  > .left {
    padding: 0.5rem 1rem;
    min-width: 120px;
  }
  > .right {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
  }
}
.word-quiz-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  //display: none;
  &.show {
    display: flex;
    animation: slideLeft 0.18s ease-in-out;
  }
}
.word-quiz {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .desc {
      font-weight: 500;
      color: rgba(var(--gray-soft--rgb), 0.7);
    }
    > .voice-btn {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--bg-light);
      box-shadow: inset -3px -3px 8px -4px rgba(48, 50, 171, 0.14);
      margin-bottom: 0.75rem;
      transition: 0.3s;
      border: 1px solid var(--bg-light);
      position: relative;
      &.active {
        box-shadow: inset 2px 9px 10px -1px rgba(48, 50, 171, 0.14);
        box-shadow: inset 4px 1px 23px -1px rgba(48, 50, 42, 0.12);
        &:before {
          content: '';
          position: absolute;
          display: block;
          right: -4px;
          left: -4px;
          bottom: -4px;
          top: -4px;

          box-shadow: 0 0 0 0.15rem rgba(38, 143, 255, 0.7),
            0 0 0 0.4rem rgba(38, 143, 255, 0.4);
          border-radius: 50%;
        }
      }
    }
  }
}

.word-quiz-options {
  display: flex;
  flex-direction: column;

  .word-quiz-option {
    text-align: center;
    padding: 1rem;
    background-color: var(--bg-light);
    margin-bottom: 0.5rem;
    color: rgba(var(--gray-soft--rgb), 0.7);
    font-size: 1rem;
    font-weight: 700;
    border-radius: 16px;
    cursor: pointer;
  }
}

.wq-option {
  display: block;
  margin-bottom: 0.5rem;
  border-radius: 16px;
  background-color: var(--bg-light);
  color: rgba(var(--gray-soft--rgb), 0.7);
  padding: 1rem;
  text-align: center;
  font-weight: 700;
  &.wrong {
    background-color: rgba(var(--main-red--rgb), 0.8);
    color: white;
  }
  &.correct {
    background-color: rgba(var(--main-green--rgb), 0.8);
    color: white;
  }
  &.selected {
    background-color: var(--primary-color);
    color: white;
    .q-number {
      background-color: var(--bg-light);
      color: var(--gray-soft);
    }
  }
}

@include min-width(1024px) {
  .word-quiz {
    width: 50%;
    &__header {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
    &__body {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }
  .words-completed {
    width: 30%;
  }
  .hover-option {
    &:hover {
      cursor: pointer;
      background-color: rgba(var(--gray-soft--rgb), 0.1);
      color: rgba(var(--gray-soft--rgb), 0.7) !important;
    }
  }
}
@include min-width(1200px) {
  .word-group {
    > .right {
      border-left: 1px solid rgba(var(--gray-soft--rgb), 0.2);
    }
  }
}
@include max-width(1200px) {
  .word-group {
    align-items: flex-start;
    flex-direction: column;
    > .right {
      width: 100%;
    }
  }
}
@include max-width(1024px) {
  .word-group-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@include max-width(768px) {
  .word-group-grid {
    grid-template-columns: 1fr;
  }
}
