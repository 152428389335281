@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// @mixin sm {
//   @media (min-width: #{$sm-min}) and (max-width: #{$md-min - 1px}) {
//     @content;
//   }
// }

// @mixin md {
//   @media (min-width: #{$md-min}) and (max-width: #{$lg-min - 1px}) {
//     @content;
//   }
// }

// @mixin lg {
//   @media (min-width: #{$lg-min}) and (max-width: #{$xl-min - 1px}) {
//     @content;
//   }
// }

// @mixin xl {
//   @media (min-width: #{$xl-min}) {
//     @content;
//   }
// }

@mixin min-width($min-width) {
  @media (min-width: #{$min-width}) {
    @content;
  }
}

@mixin max-width($max-width) {
  @media (max-width: #{$max-width}) {
    @content;
  }
}
