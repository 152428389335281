.text {
  &-primary {
    color: var(--primary-color) !important;
  }
  &-line-through {
    text-decoration: line-through;
  }
  &-muted {
    color: rgba($color: var(--gray-soft--rgb), $alpha: 0.7) !important;
  }
  &-underline {
    text-decoration: underline;
  }
  &-red {
    color: var(--main-red) !important;
  }
  &-green {
    color: var(--main-green) !important;
  }
  &-purple {
    color: var(--main-purple) !important;
  }
  &-link {
    color: $yellow !important;
  }
  &-gray-dark {
    color: var(--gray-dark);
  }
  &-gray-soft {
    color: var(--gray-soft);
  }
  &-justify {
    text-align: justify;
  }
  &-navy {
    color: var(--exam-text);
  }
}

.outline-primary {
  border: 3px solid var(--primary-color) !important;
}
.border {
  &-primary {
    border: 2px solid var(--primary-color);
  }
}
.bg {
  &-primary {
    background-color: var(--primary-color) !important;
  }
  &-purple {
    background-color: var(--main-purple) !important;
  }
  &-light {
    background-color: var(--bg-light) !important;
    &-blue {
      background-color: #3271e61c;
    }
    &-red {
      background-color: #ff87875e;
    }
  }
  &-gray {
    background-color: rgba(var(--gray-soft--rgb), 0.2) !important;
  }
  &-yellow {
    background-color: yellow;
  }
}
.fs {
  &-12 {
    font-size: 12px !important;
  }
}
.hidden {
  display: none;
}
.primary-title {
  font-size: 1.1rem;
  color: var(--font-color);
  font-weight: 700;
  margin-bottom: 0;
}

.secondary-title {
  font-size: 0.9rem;
  color: $gray-soft;
}
.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}
.rotate-90 {
  transform: rotate(90deg);
}
.component-tip {
  position: relative;
  &::before {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    bottom: -40px;
    right: 0;
    background-color: var(--gray-dark);
    color: white;
    content: attr(comp-tip);
    padding: 5px 10px;
    border-radius: 9px;
    transition: all 0.1s ease-in;
    min-width: 200px;
    font-size: 0.9rem;
    text-align: center;
  }

  &:hover {
    &::before {
      opacity: 1;
      visibility: visible;
      bottom: -30px;
    }
  }
}

@for $i from 1 through 12 {
  .ml-#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .mt-#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }
  .mr-#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }

  .mb-#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .mx-#{$i * 5} {
    margin-right: #{$i * 5}px !important;
    margin-left: #{$i * 5}px !important;
  }
  .my-#{$i * 5} {
    margin-top: #{$i * 5}px !important;
    margin-bottom: #{$i * 5}px !important;
  }
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}

.m-0 {
  margin: 0 !important;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-0 {
  margin: 0 !important;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@for $i from 1 through 12 {
  .p-#{$i * 5} {
    padding: #{$i * 5}px !important;
  }
  .pl-#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }

  .pt-#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }

  .pr-#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }

  .pb-#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .px-#{$i * 5} {
    padding-right: #{$i * 5}px !important;
    padding-left: #{$i * 5}px !important;
  }
  .py-#{$i * 5} {
    padding-top: #{$i * 5}px !important;
    padding-bottom: #{$i * 5}px !important;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@for $i from 1 through 4 {
  .line-clamp-#{$i} {
    -webkit-line-clamp: #{$i};
  }
}
.word-break {
  word-break: break-all;
}

.p-0 {
  padding: 0 !important;
}
.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.hover-display-block {
  &:hover {
    display: block;
  }
}

@for $i from 1 through 9 {
  .fw-#{$i * 100} {
    font-weight: #{$i * 100};
  }
}

.fw-light {
  font-weight: light;
}
.fw-bold {
  font-weight: bold;
}
@include max-width(767px) {
  .hide-less-md {
    display: none !important;
  }
  .p-mobile-0 {
    padding: 0 !important;
  }
  .p-mobile-10 {
    padding: 10px !important;
  }
}
@include min-width(768px) {
  .d-md-flex {
    display: flex !important;
  }
  .hide-more-md {
    display: none !important;
  }
  .w-md-50 {
    width: 50%;
  }
  .w-md-75 {
    width: 75%;
  }
}
@include min-width(1024px) {
  .w-lg-50 {
    width: 50%;
  }
  .w-lg-75 {
    width: 75%;
  }
}
@include min-width(1600px) {
  .w-xxl-75 {
    width: 75%;
  }
}

// @include sm {
//   .hide-sm {
//     display: none;
//   }
// }
// @include md {
//   .hide-md {
//     display: none;
//   }
// }
// @include lg {
//   .hide-lg {
//     display: none;
//   }
// }
// @include xl {
//   .hide-lg {
//     display: none;
//   }
// }
