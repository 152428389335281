.quiz-card {
  border-radius: 16px;
  &__body {
    display: grid;
    grid-template-columns: 65% 35%;

    .quiz-card__left {
      background-color: var(--bg-light);
      padding: 0.75rem 1rem;
      border-radius: 12px 0 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }
    .quiz-card__right {
      background-color: rgba(var(--gray-soft--rgb), 0.08);
      padding: 0.75rem 1rem;
      border-radius: 0 12px 12px 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn-white {
        > .ico {
          background-color: var(--bg-light);
        }
        > .text {
          flex: 1;
          text-align: center;
        }
        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }
  &__footer {
    display: none;
    padding: 1.3rem;
    background-color: rgba(var(--gray-soft--rgb), 0.05);
    border-radius: 0 0 12px 12px;
  }

  &.footer-active {
    .quiz-card__body {
      .quiz-card__left {
        border-radius: 12px 0 0 0;
      }
      .quiz-card__right {
        border-radius: 0 12px 0 0;
      }
    }
    .quiz-card__footer {
      display: block;
    }
  }
}

.quiz-result-grid {
  display: grid;
  //grid-template-columns: 13% 13% 13% 13% 13% 13% 22%;
  grid-template-columns: repeat(8, 1fr);
  align-items: center;
  color: rgba(var(--gray-soft--rgb), 0.8);
}

.result-bar {
  padding: 1rem;
  font-size: 0.8rem;
  color: white;
  border-radius: 6px;
  text-align: center;
  font-weight: 500;
  &.success {
    background-color: rgba(var(--main-green--rgb), 0.8);
  }
  &.unsuccess {
    background-color: rgba(var(--main-red--rgb), 0.8);
  }
}
// .rs-bar {
//   text-decoration: none;
//   font-size: 14px;
//   display: flex;
//   align-items: center;
//   margin-top: auto;
//   overflow: hidden;
//   color: #9c9cab;
//   padding: 0 20px;
//   height: 52px;
//   flex-shrink: 0;
//   position: relative;
//   justify-content: center;
//   .front {
//     display: flex;
//     align-items: center;
//     transition: 0.3s;
//   }
//   .back {
//     position: absolute;
//     color: coral;
//     font-weight: 600;
//     left: 0;
//     top: -100%;
//     display: flex;
//     transition: 0.3s;
//     padding: 0 20px;
//     align-items: center;
//     justify-content: center;
//     background-color: cornflowerblue;
//     width: 100%;
//     height: 100%;
//   }
//   &:hover {
//     .front {
//       transform: translateY(100%);
//     }
//     .back {
//       top: 0;
//     }
//   }
// }

.result-title {
  color: var(--gray-soft);
  font-weight: 500;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    display: block;
    max-width: 9px;
    min-width: 9px;
    height: 9px;
    border-radius: 50%;
    margin-left: 1rem;
  }

  &.success {
    &::after {
      background-color: rgba(var(--main-green--rgb), 0.8);
    }
  }
  &.unsuccess {
    &::after {
      background-color: rgba(var(--main-red--rgb), 0.8);
    }
  }
  &.empty {
    &::after {
      background-color: rgba(var(--gray-dark--rgb), 0.8);
    }
  }
}

@include max-width(1024px) {
  .quiz-card__footer {
    padding: 1rem;
  }
  .quiz-card__left {
    border-top-right-radius: 12px !important;
    border-bottom-left-radius: 0px !important;

    flex-direction: column;
    align-items: flex-start !important;
  }
  .quiz-card__body {
    grid-template-columns: 1fr;
    .quiz-card__right {
      border-radius: 0 !important;
    }
  }
  .quiz-result-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem 1rem;
  }

  .radius-md-12 {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }
}
