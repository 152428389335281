.leftTabMenu,
.calendarTabMenu {
  .nav-link {
    color: rgba(var(--gray-soft--rgb), 0.8);
    text-transform: uppercase;
    font-size: 1rem;
    flex: 1;
    font-weight: 600;
    &.active {
      background-color: #ffffff00;
      border-bottom: 2px solid var(--primary-color);
      color: var(--primary-color);
      border-radius: 0;
    }
  }
}
.calendarTabMenu {
  .nav-link {
    font-weight: 500;
  }
}

.quiz-select {
  width: 100%;
  padding: 0.75rem 3rem 0.75rem 0.75rem;
  border: none;
  background-color: rgba(var(--primary-color--rgb), 0.08);
  color: var(--gray-soft);
  outline: none;
  border-radius: 12px;
  font-weight: 600;
  -webkit-appearance: none;
  background-position: right 10px top 4px;
  background-size: contain;

  background-repeat: no-repeat;
  background-image: url('assets/icons/triangle.svg');
}

.magaza-intro {
  background: linear-gradient(269.52deg, #32a7e6 0.06%, #3271e6 98.99%);
  box-shadow: 0px 16px 24px rgba(113, 123, 150, 0.06),
    0px 2px 6px rgba(113, 123, 150, 0.04), 0px 0px 1px rgba(113, 123, 150, 0.04);
  padding: 1rem clamp(0.4rem, 100%, 0.8rem);
  border-radius: 12px;
  display: flex;
  align-items: center;
}
.magaza-btn {
  padding: clamp(0.5rem, 100vw, 1rem) clamp(0.5rem, 100vw, 1rem);
  background-color: white !important;
  > .text {
    color: black;
  }
}

.daily-paragraph {
  background-color: var(--bg-light);
  border-radius: 16px;
  box-shadow: var(--main-shadow);
  &__header {
    display: grid;
    //grid-template-columns: 35% 65%;
    align-items: center;
    padding: 0.5rem 1.5rem;

    > .img {
      position: relative;
      height: 0;
      padding-top: 60%;
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100% !important;
        height: 100% !important;
        border-radius: 14px;
      }
    }
    > .title {
      color: var(--gray-dark);
      font-weight: 700;
      text-transform: capitalize;
      font-size: 1.3rem;
    }
  }
  &__body {
    padding: 0.5rem 1.5rem;
    .sample {
      text-align: justify;
      //columns: 2;
      column-gap: 30px;
      column-rule: 1px solid #f0f0f0;
      &:first-letter {
        font-size: 200%;
        font-weight: 700;
        text-transform: uppercase;
      }
      img {
        aspect-ratio: 16/5;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.paragraph-card {
  border: 1px solid rgba(var(--gray-soft--rgb), 0.2) !important;
  display: block;
  background-color: var(--bg-light);
  color: var(--font-color) !important;
  padding: 1rem 0.75rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  &_placeholder {
    min-height: 100px;
    max-height: 100px;
    height: 100%;
    //background-color: rgba(var(--bg-color--rgb), 0.53);
    background: repeating-linear-gradient(
      -45deg,
      rgba(var(--main-red--rgb), 0.05),
      rgba(var(--main-red--rgb), 0.05) 10px,
      rgba(var(--main-red--rgb), 0.01) 10px,
      rgba(var(--main-red--rgb), 0.01) 20px
    );
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_img {
    border: 1px solid rgba(var(--gray-soft--rgb), 0.08) !important;
    height: 100%;
    min-height: 100px;
    max-height: 100px;

    object-fit: cover;
    object-position: center;
  }
  &.is-read {
    opacity: 0.7;
    &::before {
      content: '';
      position: absolute;
      color: #fff;
      font-size: 0.7rem;
      right: -2px;
      top: -2px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 65px 65px 0;
      border-color: transparent green transparent transparent;
      z-index: 1;
    }
    &::after {
      content: 'OKUNDU';
      position: absolute;
      color: #fff;
      font-size: 55%;
      right: -5px;
      top: 16px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 50px;
      text-align: center;
      z-index: 1;
    }
  }
}
.paragraph-swiper {
  .swiper-slide {
    width: 310px;
  }
}

.img-prg {
  height: 100%;
  max-height: 175px;
  object-fit: cover;
  object-position: center;
}

.limited-box-wrapper {
  position: relative;
  &::after {
    bottom: 0;
    content: '';
    height: 100px;
    position: absolute;
    width: 100%;
  }
}
.limited-box {
  padding: 0 0.75rem;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  position: relative;
}

.word-point {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: flex-end;
  li {
    display: inline-block;
    width: 10px;
    background-color: rgba(var(--gray-soft--rgb), 0.3);
    margin-right: 3px;
    border-radius: 7px;

    &:nth-child(1) {
      height: 25%;
    }
    &:nth-child(2) {
      height: 50%;
    }
    &:nth-child(3) {
      height: 75%;
    }
    &:nth-child(4) {
      height: 100%;
    }
  }
}

.daily-word {
  border-radius: 16px;
  box-shadow: var(--main-shadow);
  border-radius: 16px;
  background-color: var(--bg-light);
  transition: 0.2s;
  border: 3px solid transparent;

  &__header {
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    padding: 1.2rem;
    .voice-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.6rem 0.8rem;
      border-radius: 12px;
      margin-right: 10px;
      background-color: rgba(var(--gray-soft--rgb), 0.5);
      cursor: pointer;
      transition: 0.2s;
      &.active {
        background-color: var(--primary-color);
        animation: pulseSmall 1.5s infinite;
      }
    }
    .badge {
      padding: 4px !important;
      font-size: 10px;
    }
    .word-details {
      > .word {
        //color: var(--primary-color);
        font-family: cursive;
        font-size: 1.1rem;
        font-weight: 700;
        line-height: 0.75;
        display: block;
      }
      .mean {
        font-size: 0.95rem;
        font-weight: 400;
        color: rgba(var(--gray-soft--rgb), 0.8);
        line-height: 0.75;
        margin-top: 7px;
        margin-bottom: 5px;
      }
    }
  }
  &__body {
    padding: 1.2rem;
    color: var(--gray-soft);
    font-weight: 500;
  }
  &__footer {
    color: var(--gray-soft);
    font-size: 0.9rem;
    padding: 1.2rem 1.2rem;
  }

  &.wq-word-result {
    .word-details {
      > .word {
        font-size: 1.1rem;
        > .mean {
          font-size: 0.9rem;
        }
      }
    }
  }
}

.calendar-event-card {
  display: flex;
  > .ico {
    width: 70px;
    min-width: 70px;
    max-height: 80px;
    border-radius: 16px;
    background-color: var(--bg-light);
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.75rem;
    position: relative;
    &::after {
      content: '';
      min-width: 25px;
      max-width: 25px;
      height: 25px;
      border: 5px solid var(--bg-light);
      border-radius: 16px;
      background-color: var(--primary-color);
      display: inline-block;
      position: absolute;
      bottom: -12px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  > .right {
    display: flex;
    flex-direction: column;
    color: var(--gray-soft);
    > .title {
      font-weight: 600;
      color: var(--gray-dark);
    }
  }
}
.home-grid {
  display: grid;
  grid-template-columns: 100%;
  &__right,
  &__left {
    padding: 1rem 0;
  }
}
.favorite-grid {
  display: flex;
  gap: 10px;
  //grid-template-columns: repeat(5, 1fr);
}

.favorite {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  border-radius: 12px;
  width: 100%;
  background-color: var(--bg-light);
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif !important;
  text-align: center;
  min-height: 175px;
  margin-bottom: 1rem;

  //box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px inset;
  img {
    width: 100px;
    position: relative;
    margin-top: -40px;
  }
  &-text {
    font-size: 1.1rem;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.02em;
    color: var(--quiz-navy);
  }

  &.blue {
    background-color: #27a5ec;
  }
  &.green {
    background-color: #16ac25;
  }
  &.red {
    background-color: #e1432e;
  }
  &.gray {
    background-color: $gray-soft;
  }
  &:hover {
    animation: pop-top 0.3s ease-in-out;
  }
}

@include min-width(1200px) {
  .home-grid {
    grid-template-columns: 30% 70%;
    gap: 1.5rem;
  }
  .img-prg {
    height: 100%;
    max-height: 250px;
    object-fit: cover;
    object-position: center;
  }
  .w-lg-75 {
    width: 75%;
  }
}
@include min-width(1400px) {
  .home-grid {
    grid-template-columns: 25% 75%;
    gap: 1.5rem;
    &__right,
    &__left {
      padding: 1.4rem 0;
    }
  }
}
@include max-width(978px) {
  .favorite-grid {
    flex-wrap: wrap;
  }
}

@include max-width(1200px) {
  .header-swiper__icons,
  .header-swiper {
    display: none;
  }
}
@include max-width(1024px) {
  .navbar-top__menu {
    .btn {
      .text {
        display: none;
      }
    }
  }
}
@include max-width(768px) {
  .daily-paragraph {
    &__header {
      grid-template-columns: 100%;
      > .title {
        padding-left: 0;
        margin-top: 10px;
      }
    }
    &__body {
      .sample {
        columns: unset;
      }
    }
  }
}
