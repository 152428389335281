
































































































.word-rate {
  display: flex;
  align-items: flex-end;
  svg {
    margin-left: 3px;
  }
}
