.form-control {
  &:focus {
    color: #212529;
    border-color: transparent;
    outline: 0;
    box-shadow: none;
  }
}
a {
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.dropdown {
  &.show {
    .dropdown-btn {
      &::before {
        content: '';
        position: absolute;
        background-color: rgba(147, 147, 147, 0.84);
        bottom: -16px;
        left: 50%;
        width: 12px;
        height: 12px;
        border-radius: 3px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-transform: translateX(-50%) rotate(45deg);
        transform: translateX(-50%) rotate(45deg);
        -webkit-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
      }
    }
  }
}

.dropdown-menu {
  min-width: 200px;
  opacity: 0;
  visibility: hidden;
  display: flex !important;
  transform: scale(0) !important;
  transition: 300ms cubic-bezier(0.11, 0.02, 0, 1.24);
  top: 60px !important;
  border: none;
  box-shadow: var(--main-shadow);
  right: 0 !important;
  left: auto !important;
  background-color: var(--bg-light);
  color: var(--font-color);
  padding: 0;
  &.show {
    opacity: 1;
    visibility: visible;
    transform: scale(1) !important;
  }
}

.form-select {
  height: 50px;
  font-size: 0.8rem;
  background-image: url('assets/icons/select-down.svg');
  background-size: 35px 35px;
  background-position: right 0.4rem center;
  border-radius: 16px;
  border: 1px solid transparent;
  color: var(--font-color) !important;

  background-color: rgba(var(--gray-soft--rgb), 0.1);
  padding-right: 3rem;
  padding-left: 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  &:focus {
    border: 1px solid rgba(var(--gray-soft--rgb), 0.2) !important;
    outline: none;
  }
}

.progress {
  background-color: rgba(var(--gray-soft--rgb), 0.2);
  border-radius: 16px;
  &-bar {
    background-color: var(--primary-color);
  }
  &.filled{
    background-color: rgba(var(--primary-color--rgb), 0.2)
  }
}
.legend-custom-style {
  line-height: 1;
  small {
    font-size: 12px;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
h1,
h2,
h3,
h4,
h5 {
  letter-spacing: 0.01rem !important;
}
