.tab-menu {
  &--primary {
    width: fit-content;
    //box-shadow: $tab-menu-shadow;
    border-radius: 20px;
    display: block;

    .nav-item {
      display: inline-block;
      flex: auto;
      .nav-link {
        border-radius: 16px;
        color: var(--gray-soft);
        height: 50px;
        padding: 10px 1.2rem;
        font-size: 13px;
        font-weight: 600;
        line-height: 2.5;
        width: 100%;
        text-align: center;

        &.active {
          background-color: rgba(var(--gray-soft--rgb), 0.1);
        }
      }
    }
  }

  &--submenu {
    .nav-item {
      margin-right: 1.2rem;
      .nav-link {
        border-radius: 0;
        font-size: 0.9rem;
        font-weight: 500;
        padding: 10px 0;
        &.active {
          background-color: transparent;
          border-bottom: 1px solid rgba(var(--gray-soft--rgb), 0.3);
        }
      }
    }
  }
}
