$fontPrimary: 'Rubik', sans-serif;
$fontMontserrat: 'Montserrat', sans-serif;
$font-size: 15px;

//colors
$primary-color: #3271e6;
//$main-red: #e14f3a;
$main-red: #bd1a20;
$main-green: #31b857;
$main-purple: #4f3075;
$main-orange: #fe9a38;
$gray-soft: #717b96;
$gray-dark: #3c4858;
$bg-color: #f3f3f4;
$bg-light: #ffffff;
$light-blue: #e8ecf4;

$font-color: $gray-dark;
$nav-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
$main-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

//breakpoints
$sm-min: 640px;
$md-min: 768px;
$lg-min: 1024px;
$xl-min: 1200px;

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root {
  --primary-color: #{$primary-color};
  --main-red: #{$main-red};
  --main-green: #{$main-green};
  --main-purple: #{$main-purple};
  --main-orange: #{$main-orange};
  --light-blue: #{$light-blue};
  --gray-soft: #{$gray-soft};
  --gray-dark: #{$gray-dark};
  --bg-color: #{$bg-color};
  --bg-light: #{$bg-light};
  --font-color: #{$font-color};
  --nav-shadow: #{$nav-shadow};
  --main-shadow: #{$main-shadow};
  --drop-bg: #ededf0;
  --qn-color: white;
  --quiz-navy: #003559;
  --exam-text: #003559;
  --table-head-color: rgba(0, 53, 89, 0.1);
  --table-cell-color: white;

  --primary-color--rgb: #{hexToRGB($primary-color)};
  --main-red--rgb: #{hexToRGB($main-red)};
  --main-green--rgb: #{hexToRGB($main-green)};
  --main-purple--rgb: #{hexToRGB($main-purple)};
  --main-orange--rgb: #{hexToRGB($main-orange)};
  --gray-soft--rgb: #{hexToRGB($gray-soft)};
  --gray-dark--rgb: #{hexToRGB($gray-dark)};
  --bg-color--rgb: #{hexToRGB($bg-color)};
  --bg-light--rgb: #{hexToRGB($bg-color)};
  --font-color--rgb: #{hexToRGB($font-color)};
  --light-blue--rgb: #{hexToRGB($light-blue)};
  --plyr-color-main: #ff0a17;
}

[data-theme='dark'] {
  --primary-color: #{darken($primary-color, 5%)};
  --main-red: #{darken($main-red, 10%)};
  --main-green: #{darken($main-green, 10%)};
  --main-purple: #{lighten($main-purple, 20%)};
  --main-orange: #{lighten($main-orange, 20%)};
  --gray-soft: #{lighten($gray-soft, 45%)};
  --gray-dark: #{lighten($gray-dark, 45%)};
  --font-color: #{darken(#fff, 10%)};
  --bg-color: #151f2f;
  --bg-light: #293342;
  --nav-shadow: inset 0 0 35px -13.5px #0000004a;
  --qn-color: #606176;
  --quiz-navy: #1884c8;
  --exam-text: white;
  --table-head-color: rgba(58, 134, 186, 0.6);
  --table-cell-color: #3675a25e;

  --light-blue: #{darken($light-blue, 70%)};
  --drop-bg: #1f2939;
}
