.translate-btn {
  position: relative;
  background-color: var(--bg-light);
  border-radius: 50em;
  &:focus {
    box-shadow: none;
  }
  &.active {
    box-shadow: 0 0 0 0.15rem rgba(38, 143, 255, 0.7),
      0 0 0 0.4rem rgba(38, 143, 255, 0.4);
    -webkit-animation-name: hvr-pop;
    animation-name: hvr-pop;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }

  &:hover {
    opacity: 0.8;
  }
}

.paragraph-details-card {
  text-align: justify;
  padding: 1rem;
  border-radius: 0px;
  background-color: var(--bg-light);
  .cover {
    width: 100%;
    height: 220px;
    border-radius: 16px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 30px;
  }
}
.paragraph-question-numbers {
  background-color: rgba(var(--gray-soft--rgb), 0.1);
  .__panel {
    margin-left: auto;
    margin-right: auto;
  }
}
.q-number {
  min-width: 35px;
  max-width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
  background-color: rgba(var(--gray-soft--rgb), 0.9);
  border-radius: 12px;
  color: white;
}
.close-quiz-btn {
  line-height: 30px;
  padding: 1rem;
}

@include min-width(1024px) {
  .paragraph-details-card {
    padding: 2rem;
    .cover {
      height: 500px;
    }
  }
  .close-quiz-btn {
    height: 51px;
    line-height: 30px;
    padding: 1rem 2.5rem;
  }
}
@include max-width(768px) {
  .paragraph-select {
    width: 100%;
  }
}
