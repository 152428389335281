.badge {
  font-weight: 500;
  padding: 0.5rem 0.75rem;

  &-danger {
    background-color: var(--main-red) !important;
  }
  &-primary {
    background-color: var(--primary-color);
  }
  &-feature {
    position: relative;
    top: -12px;
    border-radius: 0.75rem;
    font-size: 55%;
    padding: 0.3rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: 5px;
    box-shadow: 3px -3px 0 0 #17a2b847;
    //font-weight: 300;
    //font-family: calibri;
  }
}
