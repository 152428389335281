




























































.lesson-progress-card {
  width: 310px;
  background-color: var(--bg-light);
  color: var(--font-color) !important;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  .progress-card__left {
    min-width: 65px;
    max-width: 65px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
  }
  .progress-card__right {
    padding-left: 10px;
  }
}
.lesson-progress-swiper {
  .swiper-slide {
    width: 310px;
  }
}
