.grid-exam-card {
  display: grid;
  grid-template-columns: 1fr;
  transition: 0.3s;

  font-weight: 500;
  background-color: var(--bg-light);
  border-radius: 16px;
  border: 1px solid #6f6f6f30;

  .span {
    font-size: 13px;
    color: $gray-soft;
  }
  &__left {
    padding: 0.65rem;
    > .cover {
      width: 100%;
      height: 110px;
      background-position: top center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    img {
      border-radius: 8px;
    }
  }

  &__center {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.65rem;
    flex: 1;
  }

  &__right {
    background-color: rgba(var(--gray-soft--rgb), $alpha: 0.03);
    padding: 1rem;
    display: flex;
    align-items: center;
  }
}

@include min-width(768px) {
  .grid-exam-card {
    grid-template-columns: 15% 65% 20%;
    &__left {
      padding: 0.5rem;
      img {
        width: 100%;
        height: auto;
        background-color: rgb(199, 199, 199);
        
      }
    }
    &__center {
      border-right: 1px solid rgba(var(--gray-soft--rgb), $alpha: 0.1);
      padding: 1rem;
    }
    &__right {
    }
  }
}
