.bla-card {
  font-family: 'Nunito Sans', Arial, Helvetica, sans-serif !important;
  &__header {
    box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    border-radius: 7px;

    > .first {
      background-color: var(--quiz-navy);
      color: white;
      font-weight: 600;
      font-size: 1.1rem;
      padding: 1rem;
      border-radius: 7px 7px 0 0;
    }
    > .second {
      background-color: var(--bg-light);
      padding: 0rem 0.75rem;
      display: flex;
      //align-items: flex-end;
      justify-content: space-between;
      border-radius: 0 0 7px 7px;

      .left {
        padding: 0.75rem 0;
        font-size: 1.1rem;
        font-weight: 700;
        color: var(--exam-text);
        display: flex;
        align-items: center;
      }
      .center {
      }
      .right {
        padding: 0.75rem 0;
        display: flex;
        align-items: center;
        font-size: 1.1rem;
      }
    }
  }

  &__body {
    position: relative;
    border-radius: 7px;
    padding: 2rem;
    background-color: var(--bg-light);
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 3px 0 rgba(0, 0, 0, 0.2);
  }
}

.exam-container {
  .text {
    font-size: 1.15rem;
    font-weight: 800;
    color: var(--exam-text);
    &::selection {
      color: inherit;
      background-color: yellow;
    }
  }
  .exam-options {
    display: flex;
    flex-direction: column;
    .exam-option {
      display: flex;
      align-items: center;
      color: var(--exam-text);
      cursor: pointer;
      width: fit-content;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      input {
        display: none;
      }
      .opt-key {
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid var(--quiz-navy);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        font-weight: 900;
        transition: 0.15s;
      }
      .opt-text {
        margin-left: 10px;
        font-size: 1.15rem;
        font-weight: 600;
      }
      &.selected {
        .opt-key {
          background-color: var(--quiz-navy);
          color: white;
          animation-name: hvr-pop;
          animation-duration: 0.2s;
          animation-timing-function: linear;
          animation-iteration-count: 1;
        }
      }
    }
  }
}

.user-img {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 7px;
  background-color: #eee;
}
.q-num {
  color: var(--quiz-navy);
  font-size: 1.5rem;
  font-weight: bold;
}
.q-bookmark {
  min-width: 60px;
  max-width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-light);
  box-shadow: 0px 0px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: var(--quiz-navy);
    animation-name: hvr-pop;
    animation-duration: 0.2s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    svg {
      path {
        fill: white !important;
      }
    }
  }
}

.q-nav-btn {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-light);
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  color: var(--quiz-navy);
  font-size: 1.1rem;
  position: relative;
  cursor: pointer;

  &:active {
    background-color: var(--quiz-navy);
    i {
      color: white !important;
    }
  }
}

.quiz-cta-btns {
  display: flex;
  align-items: center;
  li {
    color: var(--gray-soft);
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    padding: 1.5rem 1rem;
    border: 3px solid transparent;
    cursor: pointer;
    transition: 0.2s;
    .txt {
      font-size: 1.1rem;
    }
    i {
      font-size: 1.6rem;
    }
    &:hover {
      box-shadow: var(--main-shadow);
      border-bottom: 3px solid var(--quiz-navy);
    }
  }
}
.btn-visible {
  background-color: rgba(var(--gray-soft--rgb), 0.2);
  min-width: 60px;
  max-width: 60px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  &.active {
    background-color: rgba(var(--gray-soft--rgb), 0.2);
  }
}

.q-alert {
  padding: 1.1rem;
  border-radius: 7px;
  border: 1px dashed var(--gray-soft);
  font-size: 1.1rem;
  font-weight: 500;

  &__info {
    background-color: rgba(31, 159, 233, 0.2);
    color: #059fff;
    border-color: #1f9fe9;
  }
  &__success {
  }
  &__danger {
    background-color: rgba(216, 86, 86, 0.2);
    border-color: #d85656;
    color: #da2828;
    i {
      font-size: 2.5rem;
    }
  }
}
.quiz-overlay {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  transition: 0.2s;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  &.active {
    display: block;
  }
}
.live-status {
  position: fixed;
  left: 0;
  top: 0;
  transform: translate(-110%);
  transition: 0.3s;
  height: 100vh;
  z-index: 1000;
  width: 100%;
  background-color: var(--bg-light);
  padding: 0.4rem;
  &-header {
    padding: 0.5rem 0.7rem;
  }
  &-content {
    height: 85vh;
    overflow-y: auto;
    padding: 0 0.7rem;
    &::-webkit-scrollbar {
      height: 4px;
    }
    &::-webkit-scrollbar-track {
      background-color: rgba(var(--gray-dark--rgb), 0.08);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(var(--gray-soft--rgb), 0.3);
    }
    &::-webkit-scrollbar-thumb:hover {
      border-radius: 10px;
      background-color: rgba(var(--gray-dark--rgb), 0.7);
    }
  }
  &.active {
    transform: translate(0%);
  }
}
.q-filter-tab {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  //justify-content: space-between;
  padding: 10px 0;
  gap: 7px 7px;
  li {
    cursor: pointer;
    flex: 1;
    text-align: center;
    box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.3);
    padding: 0.8rem 1.2rem;
    font-weight: 500;
    color: var(--exam-text);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    &.active {
      background-color: var(--primary-color);
      color: white;
    }
    &.dot {
      &::before {
        content: '';
        min-width: 12px;
        max-width: 12px;
        height: 12px;
        display: flex;
        border-radius: 50%;
        margin-right: 5px;
      }
      &.answered {
        &::before {
          background-color: var(--exam-text);
        }
      }
      &.empty {
        &::before {
          border: 1px solid var(--exam-text);
        }
      }
    }
    .marked {
    }
  }
}
.review-nums {
  .review-num {
    display: inline-block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 800;
    width: 55px;
    height: 44px;
    background-color: var(--bg-light);
    line-height: 44px;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
    display: inline-block;
    margin-right: 6px;
    margin-bottom: 6px;
    border: 1px solid #6f7a81;
    cursor: pointer;

    &::after {
      content: '';
      display: flex;
      position: absolute;
      right: 5px;
      bottom: 5px;
      min-width: 10px;
      max-width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid #6f7a81;
    }
    &.answered {
      &::after {
        background-color: var(--exam-text);
        border: 1px solid var(--exam-text);
      }
    }
    &.marked {
      &::before {
        content: '';
        position: absolute;
        display: flex;
        top: 2px;
        right: 2px;
        min-width: 15px;
        max-width: 15px;
        height: 15px;
        background-size: 15px 15px;
        background-position: center;
        background-image: url('assets/icons/marked.svg');
        background-repeat: no-repeat;
      }
    }
    &.active {
      background-color: rgba(var(--main-red--rgb), 0.1);
      border: 1px solid #dc3545;
      color: #dc3545;
    }
  }
}
.review-grid {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  display: flex;
  z-index: 999;
  transform: translateX(-110%);
  transition: 0.3s;
  &.active {
    transform: translateX(0%);
  }

  .left {
    background-color: var(--bg-light);
    width: 100%;
    height: 100%;
    padding: 1rem;
  }
  .right {
    background-color: var(--bg-light);
    padding: 2rem;
    width: 50%;
    height: 100%;
    border-left: 5px solid var(--quiz-navy);
  }
}

.live-status {
  &-header {
    padding: 0.5rem 1.2rem;
  }
}
.live-status-table {
  border-radius: 5px;
  text-align: center;
  border: 1px solid rgba(0, 53, 89, 0.3);
  width: 100%;
  color: var(--exam-text);
  font-size: 0.8rem;
  .cell {
    border: 1px solid rgba(0, 53, 89, 0.3);
    background-color: var(--table-cell-color);
    padding: 1rem 0.5rem;
    font-weight: 500;
    font-size: 0.9rem;
  }
  .head {
    border: 1px solid rgba(0, 53, 89, 0.3);
    padding: 1rem 0.5rem;
    font-weight: 500;
    background-color: var(--table-head-color);
  }
}
@include min-width(1024px) {
  .live-status-table {
    font-size: 1rem;
    .cell {
      &.big-font {
        font-size: 1.2rem;
      }
    }
  }
  .live-status {
    width: 50%;
    padding: 1rem;
    &-content {
      padding: 0 1.2rem;
    }
    &-header {
      padding: 0.5rem 1.2rem;
    }
  }
  .review-grid {
    .left {
      width: 50%;
    }
  }
  .bla-card {
    &__body {
      width: 75%;
    }
  }
  .q-num {
    position: absolute;
    top: 10px;
    left: -70px;
  }
  .q-bookmark {
    position: absolute;
    top: 60px;
    left: -105px;
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    svg {
      width: 40px;
      height: 40px;
    }
    &.active {
      svg {
        display: flex;
      }
    }
  }
  .q-nav-btn {
    position: absolute;
    top: 200px;
    transform: translateY(-50%);
    min-width: 80px;
    max-width: 80px;
    height: 80px;
    font-size: 1.5rem;
    .text {
      position: absolute;
      top: 90px;
      font-size: 1rem;
      font-weight: 600;
      text-align: center;
      min-width: 100px;
    }

    &-next {
      right: -105px;
    }
    &-prev {
      left: -105px;
    }
    &:hover {
      background-color: var(--quiz-navy);
      i {
        color: white !important;
      }
    }
  }
}
@include max-width(768px) {
  .q-filter-tab {
    grid-template-columns: 1fr;
  }
}

@include max-width(1024px) {
  .review-grid {
    .left {
      width: 100%;
    }
    .right {
      display: none;
    }
  }
  .q-nav-btns {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
  .q-nav-btn {
    &-prev {
      .text {
        position: absolute;
        left: 55px;
        min-width: 90px;
        font-size: 0.9rem;
      }
    }
    &-next {
      margin-left: auto;
      .text {
        position: absolute;
        right: 48px;
        min-width: 90px;
        font-size: 0.9rem;
      }
    }
  }
  .bla-card__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .quiz-cta-btns {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
    padding-bottom: 10px;

    li {
      padding: 0.65rem 1rem;
      padding-left: 0;
    }
  }
}

@include max-width(1400px) {
  .bla-card {
    &__header {
      .second {
        flex-direction: column;
        align-items: flex-start;
        .left {
          order: 1;
        }
        .center {
          order: 3;
          width: 100%;
        }
        .right {
          order: 2;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
    &__body {
    }
  }
}

@include min-width(1500px) {
  .q-filter-tab {
    grid-template-columns: repeat(4, 1fr);
    li {
      font-size: 1.1rem;
    }
  }
}
