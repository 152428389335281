@keyframes clouds-loop-1 {
  to {
    background-position: -1000px 0;
  }
}
.clouds-1 {
  background-image: url('assets/img/clouds_2.webp');
  animation: clouds-loop-1 105s infinite linear;
}

@keyframes clouds-loop-2 {
  to {
    background-position: -1000px 0;
  }
}
.clouds-2 {
  background-image: url('assets/img/clouds_1.webp');
  animation: clouds-loop-2 100s infinite linear;
}

@keyframes clouds-loop-3 {
  to {
    background-position: -1579px 0;
  }
}
.clouds-3 {
  background-image: url('assets/img/clouds_3.webp');
  animation: clouds-loop-3 102s infinite linear;
}

.clouds {
  //filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  pointer-events: none;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  aspect-ratio: 5/3;
  background-image: url('https://images.unsplash.com/photo-1536147116438-62679a5e01f2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGdyZWVufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60');
  background-size: cover;
  background-position: center;
}

.clouds-1,
.clouds-2,
.clouds-3 {
  background-repeat: repeat-x;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 500px;
  opacity: 0.8;
}
.clouds-1 {
  backdrop-filter: brightness(1);
}
.audio-plyr {
  .plyr--audio .plyr__controls {
    background-color: var(--bg-light);
    color: var(--font-color);
  }
}
.records-list {
  //display: flex;
  //flex-direction: column;
 // padding: 1.3rem;
  //background-color: var(--bg-light);
}
.record-item {
  display: flex;
  align-items: center;
  padding: 0.3rem 0;
  margin-bottom: 0;
  //font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  &::before {
    content: '';
    border-style: solid;
    border-width: 6px 0 6px 8px;
    border-color: transparent transparent transparent currentColor;
    margin-right: 5px;
    background-color: rgba(var(--gray-soft--rgb), 0.08);
    margin: 5px;
  }
  &:hover {
    color: var(--primary-color);

    background-color: rgba(var(--gray-soft--rgb), 0.08);
  }
  &.active {
    color: var(--primary-color);
    //background-color: rgba(var(--gray-soft--rgb), 0.08);
  }
}
