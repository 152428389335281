@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Light.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Light.woff') format('woff'),
    url('./assets/fonts/Rubik-Light.ttf') format('truetype'),
    url('./assets/fonts/Rubik-Light.svg#Rubik-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Bold.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Bold.woff') format('woff'),
    url('./assets/fonts/Rubik-Bold.ttf') format('truetype'),
    url('./assets/fonts/Rubik-Bold.svg#Rubik-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Black.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Black.woff') format('woff'),
    url('./assets/fonts/Rubik-Black.ttf') format('truetype'),
    url('./assets/fonts/Rubik-Black.svg#Rubik-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Rubik-ExtraBold.woff') format('woff'),
    url('./assets/fonts/Rubik-ExtraBold.ttf') format('truetype'),
    url('./assets/fonts/Rubik-ExtraBold.svg#Rubik-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Regular.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Regular.woff') format('woff'),
    url('./assets/fonts/Rubik-Regular.ttf') format('truetype'),
    url('./assets/fonts/Rubik-Regular.svg#Rubik-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-SemiBold.woff2') format('woff2'),
    url('./assets/fonts/Rubik-SemiBold.woff') format('woff'),
    url('./assets/fonts/Rubik-SemiBold.ttf') format('truetype'),
    url('./assets/fonts/Rubik-SemiBold.svg#Rubik-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('./assets/fonts/Rubik-Medium.woff2') format('woff2'),
    url('./assets/fonts/Rubik-Medium.woff') format('woff'),
    url('./assets/fonts/Rubik-Medium.ttf') format('truetype'),
    url('./assets/fonts/Rubik-Medium.svg#Rubik-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.eot?clz0ft');
  src: url('./assets/fonts/icomoon.eot?clz0ft#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?clz0ft') format('truetype'),
    url('./assets/fonts/icomoon.woff?clz0ft') format('woff'),
    url('./assets/fonts/icomoon.svg?clz0ft#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alert:before {
  content: '\e900';
}
.icon-android:before {
  content: '\e901';
}
.icon-apple:before {
  content: '\e902';
}
.icon-bell:before {
  content: '\e903';
}
.icon-board:before {
  content: '\e904';
}
.icon-book:before {
  content: '\e905';
}
.icon-calendar:before {
  content: '\e906';
}
.icon-calendar-finish:before {
  content: '\e907';
}
.icon-calendar-start:before {
  content: '\e908';
}
.icon-cart:before {
  content: '\e909';
}
.icon-check:before {
  content: '\e90a';
}
.icon-check-stroke:before {
  content: '\e90b';
}
.icon-chevron-down:before {
  content: '\e90c';
}
.icon-comments:before {
  content: '\e90d';
}
.icon-copy:before {
  content: '\e90e';
}
.icon-cup:before {
  content: '\e90f';
}
.icon-details:before {
  content: '\e910';
}
.icon-down:before {
  content: '\e944';
}
.icon-download:before {
  content: '\e911';
}
.icon-egitimler-menu:before {
  content: '\e912';
}
.icon-envelope:before {
  content: '\e913';
}
.icon-filter:before {
  content: '\e914';
}
.icon-gift:before {
  content: '\e915';
}
.icon-graphic:before {
  content: '\e916';
}
.icon-hide:before {
  content: '\e917';
}
.icon-home:before {
  content: '\e918';
}
.icon-info:before {
  content: '\e919';
}
.icon-kontenjan:before {
  content: '\e91a';
}
.icon-kontor:before {
  content: '\e91b';
}
.icon-left-arrow:before {
  content: '\e91c';
}
.icon-lessons:before {
  content: '\e91d';
}
.icon-level:before {
  content: '\e91e';
}
.icon-live:before {
  content: '\e91f';
}
.icon-live-1:before {
  content: '\e920';
}
.icon-location-footer:before {
  content: '\e921';
}
.icon-map-pin:before {
  content: '\e945';
}
.icon-minus:before {
  content: '\e922';
}
.icon-movie:before {
  content: '\e923';
}
.icon-not-found:before {
  content: '\e924';
}
.icon-oda:before {
  content: '\e925';
}
.icon-penline:before {
  content: '\e926';
}
.icon-phone:before {
  content: '\e927';
}
.icon-pin:before {
  content: '\e928';
}
.icon-pin-filled:before {
  content: '\e929';
}
.icon-pin-line:before {
  content: '\e92a';
}
.icon-play:before {
  content: '\e92b';
}
.icon-plus:before {
  content: '\e92c';
}
.icon-plus-stroke:before {
  content: '\e92d';
}
.icon-presentation:before {
  content: '\e92e';
}
.icon-refresh:before {
  content: '\e92f';
}
.icon-remote-connect:before {
  content: '\e930';
}
.icon-right-arrow:before {
  content: '\e931';
}
.icon-scale:before {
  content: '\e932';
}
.icon-search:before {
  content: '\e946';
}
.icon-ship-car:before {
  content: '\e933';
}
.icon-ship-device:before {
  content: '\e934';
}
.icon-ship-security:before {
  content: '\e935';
}
.icon-show:before {
  content: '\e936';
}
.icon-sort:before {
  content: '\e937';
}
.icon-star:before {
  content: '\e938';
}
.icon-statistic:before {
  content: '\e939';
}
.icon-target:before {
  content: '\e93a';
}
.icon-time:before {
  content: '\e93b';
}
.icon-translate:before {
  content: '\e93c';
}
.icon-trash:before {
  content: '\e93d';
}
.icon-unlimited-video:before {
  content: '\e93e';
}
.icon-user:before {
  content: '\e93f';
}
.icon-video-cam:before {
  content: '\e940';
}
.icon-video-cam-1:before {
  content: '\e941';
}
.icon-windows:before {
  content: '\e942';
}
.icon-world:before {
  content: '\e943';
}
