.helper-search-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg-light);
  padding: 7px;
  border-radius: 12px;
  justify-content: space-between;
  margin-bottom: 15px;

  > .left {
    .category-select {
      height: 45px;
      border: none;
      background-color: rgba(var(--gray-soft--rgb), 0.09);
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 10px;
      color: var(--font-color) !important;
    }
  }
  > .center {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;

    input {
      height: 45px;
      border: none;
      outline: none;
      width: 100%;
      padding: 0 10px;
      background-color: var(--bg-light);
      color: var(--font-color) !important;
    }
    .btn-primary {
      background-color: #44a5ff !important;
      border-color: #44a5ff !important ;
      border-radius: 9px;
    }
  }
}

.search-tips {
  li {
    display: inline-block;

    a {
      display: inline-block;
      padding: 7px 13px;
      background-color: rgba(var(--primary-color--rgb), 0.05);
      color: #44a5ff;
      border-radius: 7px;
      font-weight: 400;
      font-size: 0.9rem;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
}

.helper-box {
  display: block;
  border: 1px solid rgba(var(--gray-soft--rgb),0.2);
  background-color: var(--bg-light);
  border-radius: 16px;
  width: 100%;
  padding: 15px;
  transition: 0.1s;
  &__img {
    height: 100%;
    max-height: 150px;
    min-height: 150px;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      max-width: 230px;
      height: 100%;
    }
  }
  &__title {
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: 10px;
    svg {
      margin-left: 7px;
    }
  }
  &_subtitle {
    font-size: 0.7rem;
  }
  &:hover {
    -webkit-animation-name: hvr-pop;
    animation-name: hvr-pop;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
  }
}

.suggestions {
  display: flex;
  justify-content: center;
  align-items: center;
}

@include max-width(576px) {
  .helper-search-box {
    > .left {
      width: 100%;
      margin-bottom: 7px;
    }
  }
  .suggestions {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@include min-width(576px) {
  .helper-search-box {
    flex-direction: row;
  }
}
@include min-width(1024px) {
  .helper-search-box {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
}
