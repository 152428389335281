.panel {
  border-radius: 0.55rem;
  border: 1px solid rgba(var(--gray-soft--rgb), 0.08);
  &_header {
    border-radius: 0.55rem 0.55rem 0 0;
    padding: 1.2rem;
    background-color: rgba(var(--gray-soft--rgb), 0.2);
  }
  &_body {
    background-color: rgba(var(--gray-soft--rgb), 0.08);
    border-radius: 0 0 0.55rem 0.55rem;
    padding: 1.2rem;
  }
}
