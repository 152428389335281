a {
  color: inherit;
  text-decoration: none;
  &:hover {
    opacity: 0.9;
  }
}
button {
  background-color: transparent;
  outline: none;
  border: none;
}

.left-menu {
  list-style-type: none;
  &__item {
    color: var(--font-color);
    padding: 0;
  }
  .router-link-exact-active {
    background-color: transparent;
    //background-color: var(--bg-light);
    //box-shadow: var(--nav-shadow);
    //background-color: rgba(var(--primary-color--rgb), 0.1);
  }
  &__link {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    > svg {
      min-width: 40px;
      margin-right: 5px;
    }

    &:hover,
    &:focus {
      background-color: rgba(var(--primary-color--rgb), 0.05);
    }
  }
}

.mainWrap {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  &-btn {
    padding: 7px;
    min-width: 45px;
    max-width: 45px;
    height: 45px;
    background-color: rgba(var(--primary-color--rgb), 0.08);
    border: 1px solid rgba(var(--primary-color--rgb), 0.2);
    stroke: var(--primary-color) !important;
    border-radius: 12px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      font-weight: 900;
      color: var(--primary-color);
    }
  }
  &-left {
    position: relative;
    color: var(--font-color);
    padding: 4rem 0;
    width: 0px;
    height: 100vh;
    overflow: hidden auto;
    background-color: var(--bg-color);
    transition: all ease-in-out 0.25s;
    z-index: 88;
    //box-shadow: var(--nav-shadow);

    &.active {
      box-shadow: none;
      width: 300px;
      //transform:translateX(-300px);
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .content {
    position: relative;
    height: 100%;
    background-color: var(--bg-color);
    flex: 1;
    overflow: auto;
  }
}
.header-swiper {
  width: 550px !important;
  margin-left: 0 !important;
  color: var(--gray-soft);
  .swiper-slide {
    opacity: 0;
    visibility: hidden;
    &-active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.navbar-top {
  position: relative;
  background-color: var(--bg-color);
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  //background-color: var(--bg-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.5rem 20px;
  //z-index: 999;
  //box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  color: var(--font-color);
  &__left {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 35px;
  }
  &__menu {
    display: flex;
    align-items: center;
    list-style-type: none;
    > li {
      margin-left: 5px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: rgba(var(--gray-soft--rgb), 0.1);
  }
}

.header-btn {
  background-color: rgba(var(--bg-color--rgb), 0.09);
  height: 50px;
}

.user {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 16px;
  background-color: var(--bg-light);
  &__name {
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
  }
  &__icon {
    width: 32px;
    height: 32px;
    border-radius: 12px;
  }
}

.drop {
  &-wrapper {
    position: relative;
  }
  &-button {
    position: relative;
    z-index: 1000;

    &--active {
      background-color: white;
      border-radius: 12px;
      &::before {
        content: '';
        position: absolute;
        background-color: white;
        bottom: -20px;
        left: 50%;
        width: 12px;
        height: 12px;
        border-radius: 3px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-transform: translateX(-50%) rotate(45deg);
        transform: translateX(-50%) rotate(45deg);
        -webkit-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;
      }
    }
  }
  &-content {
    position: absolute;
    transform: scale(0);
    transition: 300ms cubic-bezier(0.11, 0.02, 0, 1.24);
    z-index: 1001;
    background-color: white;
    min-width: 300px;
    max-width: 720px;
    border-radius: 12px;
    margin-top: 0.75rem;
    padding: 1rem;
    right: 0;
    &.active {
      transform: scale(1);
    }
  }
}

.masonry {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem 1rem;
  &__item {
    background-color: rgba($color: #000000, $alpha: 1);
    min-height: 300px;
    border-radius: 12px;
    &:nth-child(1) {
      background-color: var(--primary-color);
    }
    &:nth-child(2) {
      background-color: var(--main-green);
      color: var(--font-color);
      grid-column: span 2;
    }
    &:nth-child(3) {
      background-color: var(--main-purple);
      grid-row: span 2;
    }
    &:nth-child(4) {
      background-color: var(--main-red);
      grid-column: span 2;
    }
    &:nth-child(5) {
      background-color: #e086d3;
    }
    &:nth-child(6) {
      background-color: #52b788;
    }
    &:nth-child(7) {
      background-color: #e29578;
      grid-column: span 2;
    }
    &:nth-child(8) {
      background-color: rgba(var(--main-red--rgb), 0.4);
    }
  }
}

.header-swiper__icons {
  display: flex;

  .swp-nav {
    background-color: rgba(var(--primary-color--rgb), 0.08);
    color: rgba(var(--primary-color--rgb), 0.8);
    cursor: pointer;
    &__prev {
      transform: rotate(90deg);
    }
    &__next {
      transform: rotate(-90deg);
    }
  }
}

.profile-drp-menu {
  border-radius: 16px;
  width: 280px;
  .drp-content {
    right: 0px;
    width: 100%;
  }
  .profile-menu {
    &__header {
      padding: 1.1rem;
      border-bottom: 1px solid rgba($color: $gray-soft, $alpha: 0.1);
      .title {
        text-align: center;
        font-weight: bold;
        font-size: 0.8rem;
        color: $gray-soft;
        text-transform: uppercase;
      }
    }
    &__body {
      padding: 1.1rem 0.5rem;
      form {
        display: flex;
        flex-direction: column;
      }
    }
    &__footer {
      padding: 18px 0;
      border-top: 1px solid rgba($color: $gray-soft, $alpha: 0.1);
      background-color: rgba($color: $gray-soft, $alpha: 0.03);
      .sign-up-link {
        text-align: center;
        font-weight: bold;
        font-size: 0.8rem;
        color: $red;
        text-transform: uppercase;
      }
    }
  }
}

.auth-menu {
  display: flex;
  flex-direction: column;
  font-weight: 500;

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    padding: 7px;
    font-size: 14px;

    .ico {
      min-width: 35px;
      max-width: 35px;
      height: 35px;
      border-radius: 12px;
      @extend .flex-centered;
      @extend .bg-light;
    }
    .chevron {
      transform: rotate(270deg);
    }

    &:hover {
      color: $red;
    }
  }
}
.account-left-menu {
  .ico {
    min-width: 45px;
    max-width: 45px;
    height: 45px;
  }
  .auth-menu__link {
    &:hover {
      color: $primary-color;
    }
  }
  .router-link-exact-active {
    color: $primary-color;
  }
}

@include max-width(1024px) {
  .mainWrap-left.active ~ .content {
    margin-right: -300px;
  }
}
@include min-width(1024px) {
  .auth-header-btn {
    min-width: 150px;
  }
}
@include min-width(1400px) {
  .header-swiper {
    width: 550px !important;
  }
  .mainWrap-btn {
    //position: absolute;
    //left: -90px;
  }
}
