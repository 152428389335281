details > summary {
  list-style-type: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
}
details[open] summary ~ * {
  animation: sweep 0.5s ease-in-out;
}

details > summary::-webkit-details-marker {
  display: none;
}

details > summary::before {
  content: '+ ';
}

details > summary::before {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-light);
  margin-right: 10px;
  width: 35px;
  height: 35px;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

details[open] > summary::before {
  content: '- ';
}

details[open] > summary {
  margin-bottom: 0.5rem;
}
