$browser-context: 16;
$blue: #1779ba;
$gray: #cacaca;
$red: #cc4b37;
$white: #ffffff;

@function em($pixels, $context: $browser-context) {
  @return #{$pixels/$context}em;
}



.accordion {
  padding: 0;
  margin-bottom: 0;

  div:last-child .accordion-item-details {
    border-radius: 5px;
  }
  dd {
    margin-left: 0;
  }
  &-item{
      margin-bottom: 1rem;
  }
}

.accordion-item-title {
  position: relative;
  .title {
    font-size: 1rem;
    font-weight: 600;
    color: rgba(var(--gray-soft--rgb), 0.7);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      content: '+';
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(var(--gray-dark--rgb),0.1);
      width: 35px;
      height: 35px;
      box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
      border-radius: 12px;
    }
  }
}

.accordion-item {
  &.is-active {
    .accordion-item-title {
      .title {
        &::after {
          content: '-';
          background-color: var(--main-red);
          color: white;
        }
      }
    }
  }
}

.accordion-item-details-inner{
    padding: 1rem 0;
}
.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
}

.accordion-item-trigger-icon {
  $size: 8px;
  display: block;
  position: absolute;
  top: 0;
  right: 1.25rem;
  bottom: 0;
  margin: auto;
  width: $size;
  height: $size;
  border-right: 2px solid #363636;
  border-bottom: 2px solid #363636;
  transform: translateY(-$size / 4) rotate(45deg);
  transition: transform 0.2s ease;

  .is-active & {
    transform: translateY($size / 4) rotate(225deg);
  }
}

.accordion-item-details {
  overflow: hidden;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter,
.accordion-item-leave-to {
  height: 0 !important;
}
