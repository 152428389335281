









































































































.lesson-progress-card {
  width: 310px;
  background-color: var(--bg-light);
  color: var(--font-color) !important;
  padding: 1rem 2rem 1rem 1rem;
  border-radius: 15px;
  display: flex;
  align-items: center;
  .progress-card__right {
    padding-left: 15px;
  }
}
.lesson-progress-swiper {
  .swiper-slide {
    width: 310px;
  }
}
