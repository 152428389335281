.video-details {
  &__header {
    background-color: var(--bg-light);
    padding: 1.5rem;
  }
  &__footer {
    padding: 1.5rem;
    background-color: rgba(var(--gray-soft--rgb), 0.05);
    border-radius: 0 0 5px 5px;
  }
  &__frame {
    border-radius: 12px 12px 0 0;
  }
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //place-items: center;
}
.feature-card {
  display: flex;
  align-items: center;
  //padding: 1.2rem;
  > .ico {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--gray-soft--rgb), $alpha: 0.6);
    border-radius: 12px;
    color: white;
    margin-right: 5px;
  }
}

.count-tabela {
  max-width: 18px;
  min-width: 18px;
  height: 18px;
  font-weight: 500;
  border-radius: 5px;
}

.video-source {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-light);
  padding: 0.4rem;
  border-radius: 8px;
  color: var(--gray-soft);
  height: 100%;
  > .left {
    padding: 0.6rem;
    word-break: break-word;
  }
  > .right {
    padding: 0.6rem;
    border-left: 1px solid rgba(var(--gray-soft--rgb), $alpha: 0.2);
    .btn-primary {
      border-radius: 5px;
      font-size: 1.2rem;
    }
  }
}

.lesson-videos {
  background-color: rgba(var(--gray-soft--rgb), 0.05);
  border-radius: 5px;
  padding: 1rem;
  height: 100%;
}

.lesson-videos-line {
  .videos-line-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    position: relative;
    padding: 0.5rem;
    transition: 0.2s;
    border-radius: 6px;
    margin-bottom: 5px;

    &::before {
      content: '';
      display: flex;
      min-width: 35px;
      max-width: 35px;
      height: 35px;
      background-image: url('assets/icons/play.svg');
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 50%;
      margin-right: 1rem;
      background-color: var(--primary-color);
      position: relative;
      z-index: 3;
    }
    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        display: inline-block;
        left: 22px;
        top: 20px;
        width: 8px;
        height: 120%;
        background-color: var(--primary-color);
      }
    }

    &.watched {
      color: var(--gray-soft);
      &::before,
      &::after {
        background-color: #afb4c3;
      }
      &::before {
        background-image: url('assets/icons/check.svg');
      }
    }

    &.active {
      background-color: var(--primary-color);
      color: white;
      border-radius: 6px;
      &::before {
        background-image: url('assets/icons/pause.svg');
        background-color: white;
      }

    }
    &:hover {
      background-color: darken($color: $primary-color, $amount: 0.5);
      color: white;
      &::before {
        box-shadow: var(--main-shadow);
        border: 1px solid var(--bg-light);
      }
    }
  }
}

@include max-width(768px) {
  .feature-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
  }
  .video-details {
    &__header,
    &__footer {
      padding: 1rem;
    }
  }
  .lesson-videos {
    padding: 1rem;
  }
  .layout-box {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
