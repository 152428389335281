.account-grid {
  display: grid;
  grid-template-columns: 30% 70%;
  &__left {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 50px;
  }
  &__right {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-bottom: 50px;
  }
}

.account-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ntf-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--bg-light);
  padding: 1rem;
  border-radius: 12px;
  &__title {
    font-size: 1.05rem;
    margin-bottom: 0;
    font-weight: 500;
  }
  &__desc {
    font-size: 0.9rem;
  }

  &.success {
    border-left: 6px solid rgba($color: #05b651, $alpha: 0.8);
    border-radius: 1px;
  }
  &.primary {
    border-left: 6px solid rgba(var(--primary-color--rgb), 0.8);
    border-radius: 1px;
  }
  &.sponsored {
    border: 1px solid #ffaa00;
    border-left: 6px solid #ffaa00;
    border-radius: 1px;
    border-bottom-right-radius: 25px;
    color: #ffaa00;
  }
  &.danger {
    border-left: 6px solid #e1432e;
    border-radius: 1px;
  }
  &__ico {
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    @extend .flex-centered;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px;
    border-radius: 7px;
  }
}

.img-uploader-wrap {
  position: relative;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
}
.img-uploader {
  position: relative;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  background-color: var(--bg-light);
  border: 5px solid var(--bg-light);
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 120px;
    color: transparent;
    &::-webkit-file-upload-button {
      display: none;
    }
  }
}
.profile-ico {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: -7px;
  background-color: var(--bg-light);
  //border: 3px solid var(--bg-color);
  border-radius: 50%;
  height: 30px;
  min-width: 30px;
  max-width: 30px;
  svg {
    fill: var(--font-color);
  }
  &.delete {
    top: -5px;
    z-index: 7;
    height: 35px;
    min-width: 35px;
    max-width: 35px;
    font-size: 0.8rem;
    color: var(--main-red);
    i {
      transform: rotate(45deg);
    }
  }
}

.profile-img {
  min-width: 100px;
  max-width: 100px;
  height: 100px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: var(--gray-soft);
  }
}

.account-input-group {
  display: flex;
  flex-direction: column-reverse;
  background-color: var(--bg-light);
  padding: 0.85rem;
  border-radius: 12px;
  label {
    transition: 0.1s;
    margin-bottom: 0.2rem;
  }

  input,
  textarea,
  select {
    border: none;
    width: 100%;
    outline: none;
    transition: 0.1s;
    resize: none;
    font-size: 1.075rem;
    background-color: transparent;
    color: var(--font-color);
    background-color: var(--bg-light);

    min-height: 35px;

    &:focus + label {
      color: var(--primary-color) !important;
      font-weight: 600 !important;
    }
  }

  input:disabled {
    color: rgba(var(--gray-soft--rgb), 0.7);
  }

  input {
    height: 35px;
  }
}

@include max-width(1024px) {
  .account-grid {
    grid-template-columns: 100%;
    &__left {
      order: 2;
    }
    &__right {
      order: 1;
    }
  }
}
@include min-width(1024px) {
  .account-grid {
    margin-top: 5rem;
    &__right {
      border-left: 1px solid rgba(var(--gray-soft--rgb), 0.08);
    }
  }
}
